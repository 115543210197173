.shared-guide-playbook-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:22px;
    .head-main-container {
        width: 100%;
        background: #123FBC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        .logo-wrapper {
            height: 80px;
            width: 80px;
            padding-top: 4px;
        }
        .logo-icon {
            height: 80px;
            width: 80px;
        }
        .title {
            text-align: center;
            font-family: "Poppins";
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            color: #FFF;
        }
        .share-link{
            width: 130px;
            height: 35px;
            color: white;
            background: rgba(0, 130, 250, 0.25);
            border-radius: 8px;
            margin-top:-8px;
            font-weight: 400;
            font-size: 14px;
            padding: 6px 10px;
            display:flex;
            gap:10px;
            cursor: pointer;
        }

        .commonBtn {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 600;
            line-height: 17.42px;
            letter-spacing: 0.005em;
            text-align: center;
            height: 40px;
            width: 170px;
            border-radius: 11px;
            text-transform: none;
        }
        
         .rejectBtn {
            border: 1px solid #FFF;
            color: #FFF;
            margin-right: 10px;
         }

         .approveBtn {
             color: #123FBC;
             background-color: #FFF;
           
         }
    }

    .body-main-container {
        display: flex;
        justify-content: center;
        gap: 22px;
        padding-right: 1%;
        padding-left: 1%;

        .profile-main-container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            min-width: 200px;
            width: 100%;

            .guide-details-main-container {
                width: 240px;
                height: fit-content;
                display: flex;
                flex-direction: column;
                border-radius: 6px;
                background: transparent;
                box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
                border: 2px solid #C4CFEC;

                .box-1 {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 8px;
                    padding: 12px 16px;

                }

                .box-2 {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 16px;
                    background: #FFFFFF;
                    padding: 12px 16px;
                    .head-text {
                        font-family: "Poppins";
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0.025em;
                    }
                    .evaluation-wrapper {
                    display: flex;
                    gap:8px;
                    align-items: center;
                    }
                    .text-1 {
                        font-family: "Poppins";
                        font-size: 10px;
                        font-weight: 500;
                        letter-spacing: 0.025em;
                        color: #3D3D3D;
                    }
                    .text-2 {
                        font-family: "Poppins";
                        font-size: 11px;
                        font-weight: 500;
                        letter-spacing: 0.025em;
                        color: #3D3D3D;
                    }
                    .chip {
                        width: fit-content;
                        border-radius: 4px;
                        padding: 2px 8px;
                        background: #DEE5F5;
                    }
                    .high-level-chip {
                        background: #003EE9;
                        color: #FFFFFF;
                    }
                    .medium-level-chip {
                        background: #DEE5F5;
                        color: #3D3D3D;
                    }
                    .low-level-chip {
                        background: #F2F2F2;
                        color: #3D3D3D;
                    }
                    .inner-box-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap:4px;
                    }
                }

                .single-box-wrapper {
                    .head-text {
                        font-family: "Poppins";
                        font-size: 11px;
                        font-weight: 400;
                        letter-spacing: 0.005em;
                    }
                    .data-text {
                        font-family: "Poppins";
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.005em;
                    }
                }
            }

            .test-details-main-container {
                width: 63%;
                background: white;
                border-radius: 6px;
                box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
                margin-bottom: 52px;

                .tabs-wrapper {
                    padding-left: 16px;
                    padding-right: 16px;
                }
                .MuiTabs-flexContainer {
                    display: flex;
                    gap:32px;
                }
                .MuiTab-root {
                    font-family: "Poppins";
                    font-size: 13px;
                    letter-spacing: 0.005em;
                    padding: 4px;
                    text-transform: capitalize;
                }
                .Mui-selected {
                    font-weight: 600;
                    color: #123FBC;
                }
                .MuiTabs-indicator {
                    height: 4px;
                    border-radius: 3px;
                    background-color: #123FBC;
                }
                .common-head-text {
                    font-family: "Poppins";
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.005em;
                    color: #6B6B6B;
                }
                .common-text {
                    font-family: "Poppins";
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.005em;
                    color: #3D3D3D;
                }
                .imp-text {
                    color: #DD2626;
                    font-weight: 500;
                }
                .tabs-content-wrapper {
                    padding: 16px;
                }

                .guideline-section-main-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .instruction-main-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    .instruction-wrapper {
                       display: flex;
                       justify-content: space-between;
                       gap:22px;
                       margin-left: 16px;
                       .instruction-inner-wrapper {
                        width: 48%;
                        display: flex;
                        flex-direction: column;
                        gap:4px;
                       }
                    }
                }

                    .evaluation-main-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        .table-main-wrapper {
                            margin-left: 16px;
                            width: 98%;
                            th,td {
                                border: none;
                            }
                           thead > tr > th {
                                font-family: "Poppins";
                                font-size: 12px;
                                font-weight: 600;
                                letter-spacing: 0.005em;
                                color: #7A7A7A;
                                text-align: left;
                                padding:  6px 8px;
                            }
                            tbody > tr > td {
                                font-family: "Poppins";
                                font-size: 12px;
                                font-weight: 500;
                                letter-spacing: 0.005em;
                                padding:  8px 12px;
                                text-align: left;
                                border-bottom: 4px solid #FFFFFF;
                            }
                            tbody > tr > td:first-child {
                                border-top-left-radius: 6px;
                                border-bottom-left-radius: 6px;
                            }
                            tbody > tr > td:last-child {
                                border-top-right-radius: 6px;
                                border-bottom-right-radius: 6px;
                            }

                            .strong-proceed {
                                background: #ECF9EE;
                                .result-text {
                                    color: #036914;
                                    font-weight: 600;
                                }
                            }
                            .proceed {
                                background: #F4FFE2;
                                .result-text {
                                    color: #84C51B;
                                    font-weight: 600;
                                }
                            }
                            .reject {
                                background: #FFEFE1;
                                .result-text {
                                    color: #EB7100;
                                    font-weight: 600;
                                }
                            }
                            .strong-reject {
                                background: #FAEEEE;
                                .result-text {
                                    color: #CC0F0F;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
                .resume-section-main-wrapper {
                    display: flex;
                    flex-direction: column;
                    grid-gap: 8px;
                    gap: 8px;
                    .resume-section-wrapper {
                        display: flex;
                        gap:22px;
                        .box-1 {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            .resume-intro-wrapper {
                                margin-left: 22px;
                                display: flex;
                                flex-direction: column;
                                gap:4px;
                            }
                        }
                        .box-2 {
                            width: 50%;
                            padding-left: 5%;
                        }
                    }
                }
                .common-section-main-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap:22px;
                    .bold-600 {
                        font-weight: 600;
                    }
                    .head-detail-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap:8px;
                        .topic-main-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap:2px;
                            .topic-wrapper {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                            }
                            .highlight {
                                font-weight: 600;
                                color: #123FBC;
                            }
                        }
                        .difficulty-wrapper {
                            display: flex;
                            align-items: center;
                            gap:8px;
                            margin-top: 4px;
                            .chip {
                                padding: 1px 5px;
                                border-radius: 3px;
                                font-family: "Poppins";
                                font-size: 12px;
                                font-weight: 500;
                            }
                            .easy-chip {
                                background: #BDE2C3;
                                color: #036914;
                            }
                            .medium-chip {
                                background: #FDD5B1;
                                color: #BE5B00;
                            }
                            .hard-chip {
                                background: #FFD4D4;
                                color: #AE0707;
                            }

                            
                        }
                        .difficulty-border-wrapper {
                            padding: 1px 5px;
                            border-radius: 3px;
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 500;
                            border: 1px solid #B6C4E6;
                            width: fit-content;
                            .easy-border-chip {
                                color: #036914;
                            }
                            .medium-border-chip {
                                color: #BE5B00;
                            }
                            .hard-border-chip {
                                color: #AE0707;
                            }
                        }
                    }
                }
            }
            .theory-single-ques-wrapper {
                background: #F1F5FF;
                border-radius: 4px;
                padding: 4px 12px;
            }
            .question-box-main-container {
                display: flex;
                flex-direction: column;
                gap:12px;
                .question-head-wrapper {
                    .head-text {
                        font-family: "Poppins";
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0.005em;
                        color: #595959;

                    }
                    .head-sub-text {
                        font-family: "Poppins";
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0.005em;
                        color: #848484;
                            
                    }
                }
                .question-list-main-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .single-question-summary {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        gap:22px;
                        align-items: center;   
                        .text-1 {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 600;
                            letter-spacing: 0.005em;
                            color: #3D3D3D;
                        }
                        .text-2 {
                            font-family: "Poppins";
                            font-size: 13px;
                            font-weight: 600;
                            letter-spacing: 0.005em;
                            color: #3D3D3D;
                        }
                       
                        .topic-text {
                            color: #6C6C6C;
                        }
                        .occur-text {
                            font-family: "Poppins";
                            font-size: 11px;
                            font-style: italic;
                            font-weight: 300;
                            letter-spacing: 0.005em;
                            color: #693403;

                            .bold {
                                font-weight: 600;
                            }
                        }
                        .easy {
                            color: #036914;
                        }
                        .medium {
                            color: #BE5B00;
                        }
                        .hard {
                            color: #AE0707;
                        }

                        
                    }
                    .quill-box {
                        width: 90%;
                        line-break: auto;
                        word-break: break-word;
                        display: block;
                        img{
                            max-height: 400px;
                            max-width: 60%;
                            margin: 6px;  
                            display: block;
                        }
                        p{
                            line-break: auto;
                            word-break: break-word;
                            margin-bottom: 0.2rem;
                        }
                    }

                    .single-question-details {
                        display: flex;
                        flex-direction: column;
                        gap:12px;
                        padding-left: 26px;
                        .text-1 {
                            font-family: "Poppins";
                            font-size: 13px;
                            font-weight: 400;
                            letter-spacing: 0.005em;
                            color: #3D3D3D;
                        }
                        .text-2 {
                            font-family: "Poppins";
                            font-size: 11px;
                            font-weight: 600;
                            letter-spacing: 0.025em;
                            text-transform: capitalize;
                        }
                            .white-fg {
                                color: #FFFFFF;
                            }
                            .image-btn {
                                padding: 0px 4px;
                                line-height: 1.2;
                                background: #B5B5B5;
                                .icon {
                                    font-size: 15px;
                                }
                            }
                    }

                    .link-box {
                        .link-text {
                            cursor: pointer;
                            text-decoration: underline;
                            color: #123FBC;
                        }
                    }

                    .MuiAccordion-root {
                        background: #F1F5FF;
                        box-shadow: none;
                        border-radius: 4px;
                    }
                    .MuiAccordionSummary-root {
                        padding: 4px 12px;
                        min-height: fit-content;
                    }
                    .MuiAccordionSummary-content {
                        margin: 0px;
                        border: 1px solid transparent;
                    }
                    .Mui-expanded {
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBC7E8;
                       
                        .Mui-expanded {
                            border: none;
                        }
                    }

                     .note-chip{     
                            width: fit-content;
                            background-color: #DEE5F5;
                            border-radius: 3px;
                            padding: 1px 6px;         
                            font-family: 'Poppins';
                            font-weight: 600;
                            font-size: 11px;
                            letter-spacing: 0.025em;
                            color: #3D3D3D;
                     }

                     .ques-sub-heading{
                        font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 12px;
                        letter-spacing: 0.005em;
                        color: #848484;
                        }

                    .question-topic {
                        font-family: 'Poppins';
                        font-size: 14px;
                        font-weight: 700;
                        letter-spacing: 0.005em;
                        color:rgba(18, 63, 188, 1);
                    }
                }
            }
        } 

        /* Guide Approval*/
        .guideApprovalStatusBox {
            background-color:  #FFFFFF;
            border-radius: 12px;
            width: 250px;
            padding: 10px;
            min-height: 165px;

            .rejectionIcon {
               margin-left: 5px;
               margin-bottom: 17px;
            }

            .approvedIcon {
               margin-right: 10px;
               margin-left: 10px;
               margin-top: -10px;
            }

            .reasonText {
               font-family: 'Poppins';
               font-size: 12px;
               font-weight: 400;
               line-height: 14.93px;
               letter-spacing: 0.005em;
               color: #3D3D3D;
               margin-top: -10px;
            }

            .statusTextBox {
                display: flex;
                .statusText {
                   font-family: 'Poppins';
                   font-size: 12px;
                   font-weight: 600;
                   line-height: 14.93px;
                   letter-spacing: 0.005em;
                   text-align: left;
                }

                .redText {
                   color: #CF0808;
                }
                .whiteText {
                   color: #FFFFFF;
                   margin-top: 5px;
                }
            }

            .approvedBox {
                  background-color: #17B65B;
                  height: 25px;
                  width: 100px;
                  border-radius: 12px;
            }

            .rejectedBox {
                background-color: #FADBDB;
                width: 228px;
                min-height: 53px;
                border-radius: 12px;
                padding: 10px 5px 10px 15px;
            }

            .detailBox {
               margin-left: 5px;
               .mt-15 {
                    margin-top: -15px;
               }
               .mt-8 {
                   margin-top: -8px !important;
               }
               .mt-10 {
                   margin-top: 10px;
               }

               .detailLightText{
                   font-family: 'Poppins';
                   font-size: 12px;
                   font-weight: 400;
                   line-height: 14.93px;
                   letter-spacing: 0.005em;
                   color: #3D3D3D;
               }
               .detailBoldText{
                   font-family: 'Poppins';
                   font-size: 12px;
                   font-weight: 600;
                   line-height: 14.93px;
                   letter-spacing: 0.005em;
                   color: #3D3D3D;
               }
            }
        }
    }
   
    .dot {
        font-family: 'Poppins';
        font-weight: 900;
        margin-right: 4px;
        line-height: 1.2;
      }

      .tech-stack {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 500;
        color: #3D3D3D;
        word-break: break-all;
      }
      
    //  Guide Comments

    .main-box
    {
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        .comment
        {
            margin-left: 20px;
            width: 14px;
            height: fit-content;
            display: flex;
            align-items: center;
        }
       
    }
    .blue-box
    { 
        background: #dfe6fa;
    }
    .light-yellow-box
    {
        background: #FFF6EF;
    }
    .dark-yellow-box
    {
        background: #FFE2CF;
    }

    .marginQstnBox{
        margin-top: -3%;
    }
}